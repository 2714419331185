import Loading from "pages/Loading";
import React, { ErrorInfo, ReactNode } from "react";

interface ChunkErrorBoundaryProps {
  children: ReactNode;
}

interface ChunkErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

export default class ChunkErrorBoundary extends React.Component<
  ChunkErrorBoundaryProps,
  ChunkErrorBoundaryState
> {
  constructor(props: ChunkErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  /**
   * React lifecycle method to update state when an error occurs.
   */
  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  /**
   * React lifecycle method to perform side effects when an error is caught.
   */
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Optional: Log error details to an error reporting service
    // console.error("ChunkErrorBoundary caught an error:", error, errorInfo);

    // If it's a chunk load error, handle it (e.g., reload the page).
    if (error.name === "ChunkLoadError") {
      // Option A: Immediately reload the page
      // window.location.reload();

      // Option B: Show fallback for a bit, then reload
      setTimeout(() => window.location.reload(), 1500);
    }
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      // You can customize the UI shown here before (or instead of) reloading
      return (
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <h2>Something went wrong!</h2>
          {error?.name === "ChunkLoadError" ? (
            //   <p>Reloading the page...</p>
            // ) : (
            //   <p>Please try refreshing the page.</p>
            <Loading />
          ) : (
            <Loading />
          )}
        </div>
      );
    }

    // If no error, render children normally
    return this.props.children;
  }
}
