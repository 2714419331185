import Modal from "Common/Components/Modal";

function EssayModal({
  show,
  onHide,
  type,
  content,
}: {
  show: boolean;
  onHide: () => void;
  type: string;
  content: string | null;
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      id="extraLargeModal"
      modal-center="true"
      className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
      dialogClassName="w-screen md:w-[35rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
    >
      <Modal.Header
        className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
        closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
      >
        <Modal.Title className="text-16 m-auto font-light">{type}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] overflow-auto p-4">
        <span
          dangerouslySetInnerHTML={{ __html: content || "" }}
          className="whitespace-pre-line"
        ></span>
      </Modal.Body>
    </Modal>
  );
}

export default EssayModal;
