import BasicButton from "Common/Components/BasicButton";
import Modal from "Common/Components/Modal";

function DeleteApplicationEssay({
  show,
  onHide,
  onDelete,
  isDeleting,
}: {
  show: boolean;
  onHide: () => void;
  onDelete: () => void;
  isDeleting: boolean;
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      id="extraLargeModal"
      modal-top="true"
      className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4"
      dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
    >
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4">
        <div className="text-center">Are you sure you want to delete this Application Essay ?</div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-center mb-5 gap-3">
        <BasicButton
        customColor="#dc3545"
        className="py-2 px-5 text-white btn bg-[#dc3545] border-[#dc3545] hover:text-white hover:bg-[#bd2130] hover:border-[#bd2130] focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={onDelete} disabled={isDeleting}>
          Yes
        </BasicButton>
        <BasicButton
        className="py-2 px-5 "
        disabled={isDeleting} onClick={onHide}>
          No
        </BasicButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteApplicationEssay;
