import React, { useEffect, useState } from "react";
import {
  ChevronsLeft,
  ChevronsRight,
  Gem,
  LogOut,
  Mail,
  MessagesSquare,
  Search,
  Settings,
  ShoppingCart,
  User2,
} from "lucide-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

//import images
import userProfile from "assets/images/users/user-profile.png";
import logoSm from "assets/images/logo-sm.png";
import logoDark from "assets/images/logo-dark.png";
import logoLight from "assets/images/logo-light.png";

//import components
// import LanguageDropdown from 'Common/LanguageDropdown';
import LightDark from "Common/LightDark";
import NotificationDropdown from "Common/NotificationDropdown";
import { Dropdown } from "Common/Components/Dropdown";
import { changeLeftsidebarSizeType } from "slices/thunk";
import {
  changeLeftSidebarSizeType,
  createLayoutSelector,
  useLayoutDispatch,
} from "slices/layout";
import BasicButton from "Common/Components/BasicButton";
import Modal from "Common/Components/Modal";
import {
  getAuth,
  logout,
  useAuthDispatch,
  useAuthSelector,
} from "slices/authv2";
import EmailAuthentication from "pages/EmailAuthentication";
import GMeetAuthentication from "pages/GMeetAuthentication";

const Header = ({ handleToggleDrawer, handleDrawer, sidebarData }: any) => {
  const dispatch = useDispatch<any>();
  const layoutDispatch = useLayoutDispatch();
  const authDispatch = useAuthDispatch();

  const auth = useAuthSelector(getAuth);

  const handleLogout = () => {
    authDispatch(logout());
  };
  // react-redux
  // const selectLayoutState = (state: any) => state.Layout;
  const selectLayoutProperties = createLayoutSelector(
    "layoutSidebarSizeType",
    "layoutType"
  );

  const { layoutSidebarSizeType, layoutType } = useSelector(
    selectLayoutProperties
  );

  const handleTopbarHamburgerIcon = () => {
    var windowSize = document.documentElement.clientWidth;
    let sidebarOverlay = document.getElementById("sidebar-overlay") as any;

    if (windowSize < 768) {
      // document.body.classList.add("overflow-hidden");
      if (sidebarOverlay.classList.contains("hidden")) {
        sidebarOverlay.classList.remove("hidden");
        (document as Document | any)
          .querySelector(".app-menu")
          .classList.remove("hidden");
      } else {
        sidebarOverlay.classList.add("hidden");
        (document as Document | any)
          .querySelector(".app-menu")
          .classList.add("hidden");
      }
      layoutDispatch(changeLeftSidebarSizeType("lg"));
    } else {
      layoutDispatch(
        changeLeftSidebarSizeType(layoutSidebarSizeType === "sm" ? "lg" : "sm")
      );
    }
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // resize
    const handleResizeLayout = () => {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize < 768) {
        layoutDispatch(changeLeftSidebarSizeType("lg"));
      } else if (windowSize <= 1024) {
        if (layoutType === "vertical") {
          layoutDispatch(changeLeftSidebarSizeType("sm"));
        } else {
          layoutDispatch(changeLeftSidebarSizeType("lg"));
        }
      } else {
        layoutDispatch(changeLeftSidebarSizeType("lg"));

        // layoutDispatch(changeLeftSidebarSizeType(layoutSidebarSizeType === "sm" ? "lg" : "sm"));
      }
    };

    // out side click
    const outerSideElement = () => {
      var windowSize = document.documentElement.clientWidth;
      var sidebarOverlay = document.getElementById("sidebar-overlay") as any;
      if (sidebarOverlay) {
        sidebarOverlay.addEventListener("click", () => {
          if (!sidebarOverlay.classList.contains("hidden")) {
            if (windowSize <= 768) {
              document?.querySelector(".app-menu")?.classList.add("hidden");
              // document.body.classList.remove("overflow-hidden");
              sidebarOverlay.classList.add("hidden");
            } else {
              layoutDispatch(changeLeftSidebarSizeType("lg"));
            }
          }
        });
      }
    };

    // scroll
    const scrollNavigation = () => {
      var scrollUp = document.documentElement.scrollTop;
      if (scrollUp >= 50) {
        document.getElementById("page-topbar")?.classList.add("is-sticky");
      } else {
        document.getElementById("page-topbar")?.classList.remove("is-sticky");
      }
    };

    window.addEventListener("scroll", scrollNavigation, true);
    window.addEventListener("click", outerSideElement);
    window.addEventListener("resize", handleResizeLayout);

    // Cleanup function to remove the event listeners
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
      window.removeEventListener("click", outerSideElement);
      window.removeEventListener("resize", handleResizeLayout);
    };
  }, [layoutType, dispatch, layoutDispatch]);

  // Create a URLSearchParams instance from the current window location
  const queryParams = new URLSearchParams(window.location.search);
  const completeEmailAuthorization = queryParams.get(
    "completeEmailAuthorization"
  );
  const completeGMeetAuthorization = queryParams.get(
    "completeGMeetAuthorization"
  );

  return (
    <React.Fragment>
      <header
        id="page-topbar"
        className="ltr:md:left-vertical-menu rtl:md:right-vertical-menu group-data-[sidebar-size=md]:ltr:md:left-vertical-menu-md group-data-[sidebar-size=md]:rtl:md:right-vertical-menu-md group-data-[sidebar-size=sm]:ltr:md:left-vertical-menu-sm group-data-[sidebar-size=sm]:rtl:md:right-vertical-menu-sm group-data-[layout=horizontal]:ltr:left-0 group-data-[layout=horizontal]:rtl:right-0 fixed right-0 z-[1000] left-0 print:hidden group-data-[navbar=bordered]:m-4 group-data-[navbar=bordered]:[&.is-sticky]:mt-0 transition-all ease-linear duration-300 group-data-[navbar=hidden]:hidden group-data-[navbar=scroll]:absolute group/topbar group-data-[layout=horizontal]:z-[1004]"
      >
        <div className="layout-width">
          <div className="flex bg-[#000000] items-center px-4 mx-auto  border-b-2 border-topbar group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand shadow-md h-header shadow-slate-200/50 group-data-[navbar=bordered]:rounded-md group-data-[navbar=bordered]:group-[.is-sticky]/topbar:rounded-t-none group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:border-zink-700 dark:shadow-none group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-zink-500 group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-md group-data-[navbar=bordered]:shadow-none group-data-[layout=horizontal]:group-data-[navbar=bordered]:rounded-b-none group-data-[layout=horizontal]:shadow-none group-data-[layout=horizontal]:dark:group-[.is-sticky]/topbar:shadow-none">
            <button
              onClick={handleTopbarHamburgerIcon}
              type="button"
              className="inline-flex bg-[#000000] relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-75 ease-linear rounded-md btn hover:bg-[#ffffff00] group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:dark:border-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[layout=horizontal]:flex group-data-[layout=horizontal]:md:hidden hamburger-icon bg"
              id="topnav-hamburger-icon"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="arrow-right-from-line"
                className="svg-inline--fa fa-arrow-right-from-line fa-fw fa-lg hidden group-data-[sidebar-size=sm]:block text-[10px] text-[#cccccc]"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M48 88c0-13.3-10.7-24-24-24S0 74.7 0 88L0 424c0 13.3 10.7 24 24 24s24-10.7 24-24L48 88zM440.4 273.5c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-12.9-7.6-17.5l-136-128c-9.7-9.1-24.8-8.6-33.9 1s-8.6 24.8 1 33.9L363.5 232 280 232l-128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l128 0 83.5 0-91.9 86.5c-9.7 9.1-10.1 24.3-1 33.9s24.3 10.1 33.9 1l136-128z"
                ></path>
              </svg>
            </button>
            <div className="items-center flex gap-3 ms-auto">
              <div className="items-center justify-center hidden px-5 text-center h-header group-data-[layout=horizontal]:md:flex group-data-[layout=horizontal]:ltr::pl-0 group-data-[layout=horizontal]:rtl:pr-0">
                <Link to="/">
                  <span className="hidden">
                    <img
                      src="https://collegepass.s3.ap-south-1.amazonaws.com/header-icon.png"
                      alt=""
                      className="h-6 mx-auto"
                    />
                  </span>
                  <span className="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden">
                    <img
                      src="https://collegepass.s3.ap-south-1.amazonaws.com/header-icon.png"
                      alt=""
                      className="h-6 mx-auto"
                    />
                  </span>
                </Link>
                <Link
                  to="/"
                  className="hidden group-data-[topbar=dark]:block group-data-[topbar=brand]:block"
                >
                  <span className="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden">
                    <img
                      src="https://collegepass.s3.ap-south-1.amazonaws.com/header-icon.png"
                      alt=""
                      className="h-6 mx-auto"
                    />
                  </span>
                  <span className="group-data-[topbar=dark]:block group-data-[topbar=brand]:block">
                    <img
                      src="https://collegepass.s3.ap-south-1.amazonaws.com/header-icon.png"
                      alt=""
                      className="h-6 mx-auto"
                    />
                  </span>
                </Link>
              </div>

              {completeEmailAuthorization ? (
                <div className="float-left">
                  <EmailAuthentication />
                </div>
              ) : null}

              {completeGMeetAuthorization ? (
                <div className="float-left">
                  <GMeetAuthentication />
                </div>
              ) : null}

              <div className="relative hidden ltr:ml-3 rtl:mr-3 lg:block group-data-[layout=horizontal]:hidden group-data-[layout=horizontal]:lg:block">
                <Dropdown className="relative flex items-center h-header">
                  <ul>
                    <li className="block pt-[5px] uppercase text-[13px] tracking-[.12em] whitespace-nowrap pr-5 mr-5 text-white font-normal dropdown-item">
                      <a href="https://www.collegepass.org/collegepass-plus">
                        {" "}
                        CollegePass+
                      </a>
                    </li>
                  </ul>
                  <Dropdown.Trigger
                    type="button"
                    className="inline-block p-0 transition-all duration-200 ease-linear"
                    id="dropdownAdvising"
                    data-bs-toggle="dropdown"
                  >
                    <li className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap pr-4 text-white font-normal dropdown-item">
                      Admissions Advising{" "}
                      <i className="ri-arrow-down-s-line text-[20px]"></i>
                    </li>
                  </Dropdown.Trigger>
                  <Dropdown.Content
                    placement="right-end"
                    className="!transform !translate-x-[-5px] !translate-y-[52px] absolute z-50 ltr:text-left rtl:text-right rounded-[0.25rem] shadow-md !top-4 dropdown-menu min-w-[10rem] bg-[#1a1c21] with-arrow"
                    aria-labelledby="dropdownAdvising"
                  >
                    <ul>
                      <li className="dark:border-zink-500">
                        <a
                          className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap opacity-80 py-5 px-4 text-white font-normal dropdown-item hover:text-[#e3cc80] focus:text-[#e3cc80]"
                          href="https://www.collegepass.org/ivy-league-undergraduate-admissions"
                        >
                          Undergraduate Admissions
                        </a>
                      </li>
                      <li className="border-t border-[#ffffff33] dark:border-zink-500">
                        <a
                          className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap opacity-80 py-5 px-4 text-white font-normal dropdown-item hover:text-[#e3cc80] focus:text-[#e3cc80]"
                          href="https://www.collegepass.org/ms-admissions"
                        >
                          MS/MBA Admissions
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Content>
                </Dropdown>
              </div>

              <div className="ml-2 mr-4">
                <Dropdown className="relative flex items-center h-header">
                  <Dropdown.Trigger
                    type="button"
                    className="inline-block p-0 transition-all duration-200 ease-linear"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                  >
                    <li className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap py-5 text-white font-normal dropdown-item">
                      Test Prep{" "}
                      <i className="ri-arrow-down-s-line text-[20px]"></i>
                    </li>
                  </Dropdown.Trigger>
                  <Dropdown.Content
                    placement="right-end"
                    className="!transform !translate-x-[6px] !translate-y-[52px] absolute z-50 ltr:text-left rtl:text-right rounded-[0.25rem] shadow-md !top-4 dropdown-menu min-w-[10rem] bg-[#1a1c21] with-arrow"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <ul>
                      <li className="dark:border-zink-500">
                        <a
                          className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap opacity-80 py-5 px-4 text-white font-normal dropdown-item hover:text-[#e3cc80] focus:text-[#e3cc80]"
                          href="https://www.collegepass.org/sat-tutoring"
                        >
                          SAT Tutoring
                        </a>
                      </li>
                      <li className="border-t border-[#ffffff33] dark:border-zink-500">
                        <a
                          className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap opacity-80 py-5 px-4 text-white font-normal dropdown-item hover:text-[#e3cc80] focus:text-[#e3cc80]"
                          href="https://www.collegepass.org/ib-tutoring"
                        >
                          IB/IGCSE Tutoring
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Content>
                </Dropdown>
              </div>

              <div className="ml-2 mr-5">
                <Dropdown className="relative flex items-center h-header">
                  <Dropdown.Trigger
                    type="button"
                    className="inline-block p-0 transition-all duration-200 ease-linear"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                  >
                    <div className="rounded-full flex">
                      <p className="uppercase text-[13px] tracking-[.12em] whitespace-nowrap text-white font-normal mt-[5px] mr-4">
                        {auth?.fullName?.split(" ")[0] || ""}
                      </p>
                      <img
                        src="/avatar_icon.png"
                        alt=""
                        className="h-7 rounded-full"
                      />{" "}
                      <i className="ri-arrow-down-s-line ml-2 text-white text-[20px]"></i>
                    </div>
                  </Dropdown.Trigger>
                  <Dropdown.Content
                    placement="right-end"
                    className="!transform !translate-x-[6px] !translate-y-[52px] absolute z-50 ltr:text-left rtl:text-right rounded-[0.25rem] shadow-md !top-4 dropdown-menu min-w-[10rem] bg-[#1a1c21] with-arrow"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <ul>
                      <li className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap py-5 px-4 text-white font-semibold dropdown-item">
                        My Account
                      </li>
                      <li className="border-t border-[#ffffff33] dark:border-zink-500">
                        <a
                          className="block uppercase text-[13px] text-[#fae05b] tracking-[.12em] whitespace-nowrap opacity-80 py-5 px-4 font-normal dropdown-item hover:text-[#e3cc80] focus:text-[#e3cc80]"
                          href="https://advisor.collegepass.org/"
                          target="_self"
                        >
                          Profile Builder
                        </a>
                      </li>
                      <li className="border-t border-[#ffffff33] dark:border-zink-500">
                        <a
                          className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap opacity-80 py-5 px-4 text-white font-normal dropdown-item hover:text-[#e3cc80] focus:text-[#e3cc80]"
                          href="https://www.collegepass.org/livestream"
                          target="_self"
                        >
                          Live Streams
                        </a>
                      </li>
                      <li className="border-t border-[#ffffff33] dark:border-zink-500">
                        <a
                          className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap opacity-80 py-5 px-4 text-white font-normal dropdown-item hover:text-[#e3cc80] focus:text-[#e3cc80]"
                          href="https://www.collegepass.org/masterclass"
                          target="_self"
                        >
                          Masterclasses
                        </a>
                      </li>
                      <li className="border-t border-[#ffffff33] dark:border-zink-500">
                        <a
                          className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap opacity-80 py-5 px-4 text-white font-normal dropdown-item hover:text-[#e3cc80] focus:text-[#e3cc80]"
                          href="https://www.collegepass.org/account"
                          target="_self"
                        >
                          My Plan{" "}
                        </a>
                      </li>
                      <li className="border-t border-[#ffffff33] dark:border-zink-500 text-center">
                        <a
                          className="block uppercase text-[13px] tracking-[.12em] whitespace-nowrap opacity-80 py-5 px-4 text-[#fae05b] font-normal dropdown-item hover:text-[#e3cc80] focus:text-[#e3cc80] cursor-pointer"
                          // onClick={() => setShowModal(true)}
                          onClick={() => handleLogout()}
                        >
                          Sign out
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Content>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal
        show={showModal}
        onHide={() => handleHideModal()}
        id="topModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col"
      >
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4">
          <div className="text-center">
            <p className="text-[18px]">Are you sure you want to Sign out?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mb-4 mt-4 text-center flex justify-center">
            <BasicButton
              data-modal-target="addNotesModal"
              type="button"
              className="mr-2 text-white btn bg-[#dc3545] border-[#dc3545] hover:text-white hover:bg-[#bd2130] hover:border-[#bd2130] focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              // onClick={toggle}
              // onClick={handleSubmit(onSubmit)}
              // disabled={isSubmitting}
              onClick={() => handleLogout()}
            >
              <span className="align-middle">Sign out</span>
            </BasicButton>
            <BasicButton
              data-modal-target="addNotesModal"
              customColor="red"
              className="bg-red-100 !text-red-500 hover:!text-white hover:bg-red-600 focus:text-white focus:bg-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:ring active:ring-red-100"
              onClick={() => setShowModal(false)}
              // disabled={isSubmitting}
            >
              <span className="align-middle">Cancel</span>
            </BasicButton>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Header;
