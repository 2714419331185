import React from "react";
import {
  ChevronsLeft,
  ChevronsRight,
  Gem,
  LogOut,
  Mail,
  MessagesSquare,
  Search,
  Settings,
  ShoppingCart,
  User2,
} from "lucide-react";
//import images
import logoSm from "assets/images/logo-sm.png";
import logoDark from "assets/images/logo-dark.png";
import logoLight from "assets/images/logo-light.png";

import { Link } from "react-router-dom";

import VerticalLayout from "../VerticalLayout/Index";
import withRouter from "Common/withRouter";
import SimpleBar from "simplebar-react";
import HorizontalLayout from "Layout/HorizontalLayout";
import { changeLeftsidebarSizeType } from "slices/thunk";
import { changeLeftSidebarSizeType, useLayoutDispatch } from "slices/layout";

const Sidebar = ({ layoutType, layoutSidebarSizeType, sidebarData }: any) => {
  const layoutDispatch = useLayoutDispatch();
  const handleTopbarHamburgerIcon = () => {
    var windowSize = document.documentElement.clientWidth;
    let sidebarOverlay = document.getElementById("sidebar-overlay") as any;

    if (windowSize < 768) {
      // document.body.classList.add("overflow-hidden");
      if (sidebarOverlay.classList.contains("hidden")) {
        sidebarOverlay.classList.remove("hidden");
        (document as Document | any)
          .querySelector(".app-menu")
          .classList.remove("hidden");
      } else {
        sidebarOverlay.classList.add("hidden");
        (document as Document | any)
          .querySelector(".app-menu")
          .classList.add("hidden");
      }
      layoutDispatch(changeLeftsidebarSizeType("lg"));
    } else {
      layoutDispatch(
        changeLeftSidebarSizeType(layoutSidebarSizeType === "sm" ? "lg" : "sm")
      );
    }
  };

  return (
    <React.Fragment>
      {/* ltr:border-r */}
      <div
        className={`app-menu bg-[#000000] w-[200px] rtl:border-l border-vertical-menu-border fixed bottom-0 top-0 z-[1003] transition-all duration-75 ease-linear group-data-[sidebar-size=md]:w-vertical-menu-md group-data-[sidebar-size=sm]:w-vertical-menu-sm group-data-[sidebar-size=sm]:pt-header group-data-[sidebar=dark]:bg-vertical-menu-dark group-data-[sidebar=dark]:border-vertical-menu-dark group-data-[sidebar=brand]:bg-vertical-menu-brand group-data-[sidebar=brand]:border-vertical-menu-brand group-data-[sidebar=modern]:bg-gradient-to-tr group-data-[sidebar=modern]:to-vertical-menu-to-modern group-data-[sidebar=modern]:from-vertical-menu-form-modern group-data-[layout=horizontal]:w-full group-data-[layout=horizontal]:bottom-auto group-data-[layout=horizontal]:top-header md:block print:hidden group-data-[sidebar-size=sm]:absolute group-data-[sidebar=modern]:border-vertical-menu-border-modern group-data-[layout=horizontal]:dark:bg-zink-700 group-data-[layout=horizontal]:border-t group-data-[layout=horizontal]:dark:border-zink-500 group-data-[layout=horizontal]:border-r-0 group-data-[sidebar=dark]:dark:bg-zink-700 group-data-[sidebar=dark]:dark:border-zink-600 group-data-[layout=horizontal]:group-data-[navbar=scroll]:absolute group-data-[layout=horizontal]:group-data-[navbar=bordered]:top-[calc(theme('spacing.header')_+_theme('spacing.4'))] group-data-[layout=horizontal]:group-data-[navbar=bordered]:inset-x-4 group-data-[layout=horizontal]:group-data-[navbar=hidden]:top-0 group-data-[layout=horizontal]:group-data-[navbar=hidden]:h-16 group-data-[layout=horizontal]:group-data-[navbar=bordered]:w-[calc(100%_-_2rem)] group-data-[layout=horizontal]:group-data-[navbar=bordered]:[&.sticky]:top-header group-data-[layout=horizontal]:group-data-[navbar=bordered]:rounded-b-md group-data-[layout=horizontal]:shadow-md group-data-[layout=horizontal]:shadow-slate-500/10 group-data-[layout=horizontal]:dark:shadow-zink-500/10 hidden group-data-[layout=horizontal]:opacity-0`}
        id="sidebar-scr"
      >
        <div className="flex items-center justify-center px-3 text-center h-header group-data-[layout=horizontal]:hidden group-data-[sidebar-size=sm]:fixed group-data-[sidebar-size=sm]:top-0 group-data-[sidebar-size=sm]:w-[calc(theme('spacing.vertical-menu-sm')_-_1px)]">
          <Link
            to="https://www.collegepass.org/"
            className="group-data-[sidebar=dark]:hidden group-data-[sidebar=brand]:hidden group-data-[sidebar=modern]:hidden"
          >
            <span className="hidden group-data-[sidebar-size=sm]:block">
              <img
                src="https://collegepass-logos.s3.ap-south-1.amazonaws.com/holo.png"
                alt=""
                className="h-10 mx-auto"
              />
            </span>
            <span className="group-data-[sidebar-size=sm]:hidden">
              {/* Dark logo */}
              <img
                src="https://collegepass-logos.s3.ap-south-1.amazonaws.com/chaseivy_logo.svg"
                alt=""
                className="h-[2rem] mx-auto"
              />
            </span>
          </Link>
          <Link
            to="#"
            className="hidden group-data-[sidebar=dark]:block group-data-[sidebar=brand]:block group-data-[sidebar=modern]:block"
          >
            <span className="hidden group-data-[sidebar-size=sm]:block">
              <img src={logoSm} alt="" className="h-6 mx-auto" />
            </span>
            <span className="group-data-[sidebar-size=sm]:hidden">
              {/* Light logo */}
              <img
                src="https://collegepass-logos.s3.ap-south-1.amazonaws.com/CP_white_logo+(2).png"
                alt=""
                className="h-11 mx-auto"
              />
            </span>
          </Link>
          <button
            type="button"
            className="hidden p-0 float-end"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>

          {layoutType === "vertical" && layoutSidebarSizeType !== "sm" && (
            <button
              onClick={handleTopbarHamburgerIcon}
              type="button"
              className="inline-flex bg-[#000000] absolute right-0 justify-center items-center p-0 text-topbar-item transition-all size-[28px] duration-75 ease-linear rounded-md btn hover:bg-[#ffffff00] group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:dark:border-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[layout=horizontal]:flex group-data-[layout=horizontal]:md:hidden hamburger-icon bg"
              id="topnav-hamburger-icon"
            >
              {/* <ChevronsLeft className="w-5 h-5 text-white group-data-[sidebar-size=sm]:hidden" /> */}
              <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="arrow-left-to-line"
                  className="svg-inline--fa fa-arrow-left-to-line fa-fw fa-1x group-data-[sidebar-size=sm]:hidden text-[10px] text-[#cccccc]"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M0 424c0 13.3 10.7 24 24 24s24-10.7 24-24L48 88c0-13.3-10.7-24-24-24S0 74.7 0 88L0 424zM135.6 238.5c-4.8 4.5-7.6 10.9-7.6 17.5s2.7 12.9 7.6 17.5l136 128c9.7 9.1 24.8 8.6 33.9-1s8.6-24.8-1-33.9L212.5 280l83.5 0 128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-128 0-83.5 0 91.9-86.5c9.7-9.1 10.1-24.3 1-33.9s-24.3-10.1-33.9-1l-136 128z"
                  ></path>
                </svg>
            </button>
          )}
        </div>

        {layoutType === "vertical" && layoutSidebarSizeType !== "sm" ? (
          <SimpleBar
            id="scrollbar"
            className="!scrollbar-hide group-data-[sidebar-size=md]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)] group-data-[sidebar-size=lg]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)] group-data-[layout=horizontal]:h-56 group-data-[layout=horizontal]:md:h-auto group-data-[layout=horizontal]:overflow-auto group-data-[layout=horizontal]:md:overflow-visible group-data-[layout=horizontal]:max-w-screen-2xl group-data-[layout=horizontal]:mx-auto"
          >
            <div>
              <ul
                className="group-data-[layout=horizontal]:flex group-data-[layout=horizontal]:flex-col group-data-[layout=horizontal]:md:flex-row"
                id="navbar-nav"
              >
                <VerticalLayout sidebarData={sidebarData} />
              </ul>
            </div>
          </SimpleBar>
        ) : (
          <div
            id="scrollbar"
            className="!scrollbar-hide group-data-[sidebar-size=md]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)] group-data-[sidebar-size=lg]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)] group-data-[layout=horizontal]:h-56 group-data-[layout=horizontal]:md:h-auto group-data-[layout=horizontal]:overflow-auto group-data-[layout=horizontal]:md:overflow-visible group-data-[layout=horizontal]:max-w-screen-2xl group-data-[layout=horizontal]:mx-auto"
          >
            <div>
              <ul
                className="group-data-[layout=horizontal]:flex group-data-[layout=horizontal]:flex-col group-data-[layout=horizontal]:md:flex-row"
                id="navbar-nav"
              >
                <HorizontalLayout />
              </ul>
            </div>
          </div>
        )}
      </div>

      <div
        id="sidebar-overlay"
        className="absolute inset-0 z-[1002] bg-slate-500/30 hidden"
      ></div>
    </React.Fragment>
  );
};

export default withRouter(Sidebar);
