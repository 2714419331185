import Modal from "Common/Components/Modal";

function AlertMessageModal({
  show,
  message,
}: {
  show: boolean;
  message: string | null;
}) {
  return (

    <Modal
      show={show}
        id="extraLargeModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen sm:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
      >
        <Modal.Body className="p-4">
        <p className="text-center text-[18px]">{message}</p>
        </Modal.Body>
        </Modal>
  );
}

export default AlertMessageModal;
