import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import BasicButton from "../../../Common/Components/BasicButton";
import Select from "Common/Components/Select";
import moment from "moment";

import "flatpickr/dist/themes/material_green.css"; // You can customize this theme
import AlertMessageModal from "./AlertMessageModal";
import LoadingModal from "./LoadingModal";
import EssayModal from "./EssayModal";
import MultiSelect from "Common/Components/MultiSelect";
import DeleteApplicationEssay from "./DeleteApplicationEssay";
import AxiosSingleton from "utils/axiosSingleton";
import { getAuth, useAuthSelector } from "slices/authv2";
import { numberToString } from "..";
import { Tooltip } from "flowbite-react";
import DatePicker from "react-datepicker";

const loadScript = (src: string) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve(true);
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const applicationOptions = [
  { label: "Common App", value: 1 },
  // { label: "UC", value: 2 },
  { label: "Apply Texas", value: 3 },
  { label: "Coalition for College", value: 4 },
  { label: "UCAS", value: 5 },
];
const actions = [
  { value: 1, label: "\u00A0" },
  { value: 9, label: "Student Draft Submitted" },
  { value: 12, label: "Editing in Progress" },
  { value: 10, label: "Brainstorming in Progress" },
  { value: 11, label: "Essay Finalized" },
];

const ApplicationCollegeEssays = ({
  setActiveEssays,
  activeEssays,
  totalNoOfUniversities,
  studentId,
  studentName,
}: {
  setActiveEssays: (prev: any) => void;
  activeEssays: number;
  totalNoOfUniversities: number;
  studentId: string | null | undefined | number;
  studentName?: string;
}) => {
  const axios = AxiosSingleton.getInstance();

  const auth = useAuthSelector(getAuth);

  const [showModal, setShowModal] = useState<{
    status: boolean;
    type: "Essay prompt" | "Sample Essay" | "";
    content: string | null;
  }>({ status: false, type: "", content: null });

  const handleShowModal = (
    type: "Essay prompt" | "Sample Essay" | "",
    content: string | null
  ) => {
    setShowModal({ status: true, type, content });
  };

  const handleCloseModal = () => {
    setShowModal({ status: false, type: "", content: null });
  };
  const [isUpdatingData, setIsUpdatingData] = useState<boolean>(false);

  const [alertMsg, setAlertMsg] = useState({ show: false, message: "" });

  const handleShowAlert = (message: string) => {
    setAlertMsg({ show: true, message });
  };

  const handleCloseAlert = () => {
    setAlertMsg({ show: false, message: "" });
  };

  async function authenticate(
    counsellorEmail?: string,
    docName?: string,
    userId?: string | number,
    collegeId?: string | number,
    emails?: string
  ) {
    // @ts-ignore
    const result = window.google.accounts.oauth2.initCodeClient({
      client_id:
        "761038965200-n70er1c6ke9qdcq9krhrtkkqmh1v42d2.apps.googleusercontent.com",
      scope:
        "https://www.googleapis.com/auth/drive.file" + " openid profile email",
      callback: function (res: any) {
        handleCredentialResponse(
          res,
          counsellorEmail,
          docName,
          userId,
          collegeId,
          emails
        );
      },
      // prompt: "consent",
      prompt: "select_account", // Set to 'none' to try silent authentication
      onError: function handleError(err: any) {
        console.error("Error during authentication:", err);
        // Handle the error in your application (e.g., display an error message)
      },
    });
    result.requestCode();
  }

  const createDoc = async (
    counsellorEmail: string,
    docName: string,
    userId: string | number,
    collegeId: string | number,
    emails: string
  ) => {
    setIsUpdatingData(true);
    try {
      const res = await axios.post(
        "https://new-api.collegepass.org/advising/v1/college-essays/create-application-essay-doc",
        {
          counsellorEmail: counsellorEmail,
          docName,
          userId,
          docId: collegeId,
          emails,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.CollegePassToken_AP}`,
          },
        }
      );
      setIsUpdatingData(false);

      setEssays((prev) =>
        prev.map((item) => {
          if (item.ID === collegeId) {
            item.GOOGLE_DOC_ID = res.data.data;
          }
          return item;
        })
      );
      // handleShowAlert("Essay Document Created!");

      setTimeout(() => {
        handleCloseAlert();
      }, 2000);
    } catch (err: any) {
      console.log("Error", err);
      console.log("Error", err.response);
      if (err === "Invalid credentials" || err?.response?.status === 401) {
        await authenticate(counsellorEmail, docName, userId, collegeId, emails);
      } else {
        setIsUpdatingData(false);
      }
    }
  };

  async function handleCredentialResponse(
    response: any,
    counsellorEmail?: string,
    docName?: string,
    userId?: string | number,
    collegeId?: string | number,
    emails?: string
  ) {
    console.log("Encoded JWT ID token: " + response.access_token);
    console.log("Encoded JWT ID token: " + response.refresh_token);
    console.log("Encoded JWT ID token: " + JSON.stringify(response));
    let payload = {
      grant_type: "authorization_code",
      client_id:
        "761038965200-n70er1c6ke9qdcq9krhrtkkqmh1v42d2.apps.googleusercontent.com",
      client_secret: "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",
      code: response.code,
      redirect_uri: "postmessage",
    };

    try {
      const res = await axios.post(
        `https://oauth2.googleapis.com/token`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;",
          },
        }
      );
      const response = res.data as any;

      console.log("refresh token: ", response);

      try {
        const res2 = await axios.post(
          "https://new-api.collegepass.org/advising/v1/shared/store-counsellor-email-access-credentials",
          {
            email: counsellorEmail,
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
            redirectURL: "https://developers.google.com/oauthplayground",
            client_id:
              "761038965200-n70er1c6ke9qdcq9krhrtkkqmh1v42d2.apps.googleusercontent.com",
            client_secret: "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",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.CollegePassToken_AP}`,
            },
          }
        );
        console.log("Response", res2);

        if (counsellorEmail && docName && userId && collegeId && emails) {
          try {
            await createDoc(
              counsellorEmail,
              docName,
              userId,
              collegeId,
              emails
            );
          } catch (err) {
            console.log("error creating doc", err);
          }
        }
      } catch (err) {
        console.log("error", err);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  }

  useEffect(() => {
    loadScript("https://accounts.google.com/gsi/client")
      .then((resp) => {
        /*global google*/
        // @ts-ignore
        console.log(window.google);
        // @ts-ignore
        window.google.accounts.oauth2.initTokenClient({
          client_id:
            "761038965200-n70er1c6ke9qdcq9krhrtkkqmh1v42d2.apps.googleusercontent.com",
          scope: "https://www.googleapis.com/auth/drive.file",
          callback: handleCredentialResponse,
          onError: function handleError(err: any) {
            console.error("Error during authentication:", err);
          },
        });
      })
      .catch((err) => console.error("Getting error while initializing", err));
  }, []);

  const updateEditorAction = async (essayId: number, editorAction: string) => {
    try {
      const result = await axios.post(
        `https://new-api.collegepass.org/advising/v1/college-essays/update-application-essay-editor-action`,
        { essayId, editorAction }
      );
      setEssays((prev) =>
        prev.map((item) => {
          if (item.ID === essayId) {
            item.EDITOR_ACTION = editorAction;
          }
          return item;
        })
      );
    } catch (err) {
      console.log("Error updating application type:", err);
    }
  };

  const updateCompletionDate = async (
    essayId: number,
    completionDate: string
  ) => {
    try {
      const result = await axios.post(
        `https://new-api.collegepass.org/advising/v1/college-essays/update-application-essay-completion-date`,
        { essayId, completionDate }
      );
      setEssays((prev) =>
        prev.map((item) => {
          if (item.ID === essayId) {
            item.COMPLETION_DATE = completionDate;
          }
          return item;
        })
      );
    } catch (err) {
      console.log("Error updating application type:", err);
    }
  };

  const [essays, setEssays] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getEssays = async (userId: number) => {
    setIsLoading(true);
    try {
      const result = await axios.get(
        `https://new-api.collegepass.org/advising/v1/college-essays/get-application-essays/${userId}`
      );
      setEssays(result.data.data);
      setActiveEssays((prev: number) =>
        result.data.data.reduce((value: number, item: any) => {
          if (item.GOOGLE_DOC_ID && item.NAME === "UCAS") {
            value += 1;
          }
          return value;
        }, prev)
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);
      console.log("Error", err);
    }
  };

  useEffect(() => {
    if (studentId) {
      getEssays(+studentId);
    }
  }, [studentId]);

  const [selectedApplications, setSelectedApplications] = useState<number[]>(
    []
  );

  const addApplicationEssays = async (userId: number) => {
    try {
      setIsUpdatingData(true);
      await axios.post(
        `https://new-api.collegepass.org/advising/v1/college-essays/add-application-essays`,
        {
          userId,
          applicationEssayIds: selectedApplications,
        }
      );
      getEssays(userId);
      setIsUpdatingData(false);
      // setAlertMsg({ show: true, message: "Essay Document Created!" });
      setSelectedApplications([]);
      setTimeout(() => {
        setAlertMsg({ show: false, message: "" });
      }, 1500);
    } catch (err) {
      setIsUpdatingData(false);
      console.log("Error adding application essays:", err);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState<{
    show: boolean;
    essayId: number | null;
  }>({ show: false, essayId: null });
  const [isDeleting, setIsDeleting] = useState(false);

  const handleShowDeleteModal = (essayId: number) => {
    setShowDeleteModal({ show: true, essayId });
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal({ show: false, essayId: null });
  };

  const deleteApplicationEssay = async () => {
    try {
      setIsDeleting(true);
      const result = await axios.delete(
        `https://new-api.collegepass.org/advising/v1/college-essays/delete-application-essay/34583/${showDeleteModal.essayId}`
      );
      await getEssays(+studentId!);
      handleCloseDeleteModal();
      setIsDeleting(false);
    } catch (err) {
      console.log("Error updating application type:", err);
    }
  };

  return (
    <React.Fragment>
      {auth.userRole && auth.userRole > 1 && (
        <div className="card">
          <div className="card-body">
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
              <div>
                <MultiSelect
                  label="Select Application Modes"
                  options={applicationOptions.filter(
                    (item) =>
                      !essays.find(
                        (ele) => ele.APPLICATION_ESSAY_ID === item.value
                      )
                  )}
                  placeholder="Select"
                  value={selectedApplications.map((item) => ({
                    label: applicationOptions.find((ele) => ele.value == item)
                      ?.label,
                    value: item,
                  }))}
                  onChange={(e: any) => {
                    console.log("Event data", e);
                    setSelectedApplications(e.map((item: any) => item.value));
                  }}
                />
              </div>
              <div className="mt-2">
                <BasicButton
                  className="mt-5"
                  // disabled={!selectedApplications.length}
                  onClick={() => addApplicationEssays(+studentId!)}
                >
                  Select
                </BasicButton>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card">
        <div className="card-body">
          <div className="">
            <table className="w-full">
              <thead className="ltr:text-left rtl:text-right bg-[#6b6969] text-white font-light">
                <tr>
                  <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center">
                    S.No.
                  </th>
                  <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center">
                    Application Mode
                  </th>
                  <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center">
                    Essay prompt
                  </th>
                  <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center">
                    Essay Document
                  </th>
                  <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center">
                    Completion Deadline
                  </th>
                  <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center">
                    Status
                  </th>
                  {auth?.userRole && auth.userRole > 1 && (
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="lucide lucide-trash m-auto"
                      >
                        <path d="M3 6h18" />
                        <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                        <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                      </svg>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <tr>
                    <td
                      className="text-center"
                      colSpan={auth?.userRole && auth.userRole > 1 ? 7 : 6}
                    >
                      Loading...
                    </td>
                  </tr>
                ) : !essays.length ? (
                  <tr>
                    <td
                      className="text-center"
                      colSpan={auth?.userRole && auth.userRole > 1 ? 7 : 6}
                    >
                      -
                    </td>
                  </tr>
                ) : (
                  essays.map((item, idx) => {
                    const minDate = moment().toDate();

                    return (
                      <tr key={item.ID}>
                        <td className="font-light pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center">
                          {idx + 1}
                        </td>
                        <td className="font-light pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center">
                          {item.NAME}
                        </td>
                        <td
                          className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center"
                          onClick={() =>
                            handleShowModal("Essay prompt", item.ESSAY)
                          }
                        >
                          Essay Prompt
                        </td>

                        <td className="font-light pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center">
                          <Tooltip
                            theme={{ target: "w-fit m-auto" }}
                            content={
                              auth.userRole === 1 ? (
                                item.GOOGLE_DOC_ID ? (
                                  "Open"
                                ) : (
                                  "Essay not activated"
                                )
                              ) : item.GOOGLE_DOC_ID ? (
                                "Open"
                              ) : activeEssays < totalNoOfUniversities ? (
                                "Click to activate"
                              ) : (
                                <span>
                                  {`Your current advising plan includes ${numberToString(
                                    activeEssays
                                  )} (${activeEssays}) universities!`}
                                  <br />
                                  {`You can contact your advisor if you'd like to add more universities.`}
                                </span>
                              )
                            }
                            className="text-center"
                          >
                            <i
                              aria-disabled={auth.userRole === 1}
                              onClick={() => {
                                if (item.GOOGLE_DOC_ID) {
                                  window.open(
                                    `https://docs.google.com/document/d/${item.GOOGLE_DOC_ID}`,
                                    "__blank"
                                  );
                                } else {
                                  auth?.userRole &&
                                    auth.userRole > 1 &&
                                    activeEssays < totalNoOfUniversities &&
                                    createDoc(
                                      auth.email!,
                                      // `Student Name <> Yuvraj | CollegePass`,
                                      `${studentName} <> Application Essay-${item.NAME} | CollegePass`, //
                                      studentId!,
                                      item.ID,
                                      "suraj.p@collegepass.org"
                                    );
                                }
                              }}
                              className={`fab fa-google-drive m-auto ${
                                item.GOOGLE_DOC_ID
                                  ? "text-green-600"
                                  : "text-red-600"
                              } cursor-pointer`}
                            />
                          </Tooltip>
                        </td>

                        <td className="font-light pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center">
                          <DatePicker
                            selected={
                              item.COMPLETION_DATE &&
                              item.COMPLETION_DATE !== "Invalid date"
                                ? moment(
                                    item.COMPLETION_DATE,
                                    "YYYY-MM-DD"
                                  ).toDate()
                                : undefined
                            }
                            // showTimeSelect
                            onChange={(date: Date | null) => {
                              if (date) {
                                // setStartDate(date);
                                updateCompletionDate(
                                  item.ID,
                                  moment(date).format("YYYY-MM-DD")
                                );
                              }
                            }}
                            disabled={auth.userRole === 1}
                            minDate={minDate}
                            placeholderText="Select"
                            className={
                              (item.COMPLETION_DATE
                                ? item.EDITOR_ACTION != 11 &&
                                  moment(
                                    item.COMPLETION_DATE,
                                    "YYYY-MM-DD"
                                  ).diff(moment(), "days") <= 3
                                  ? "text-red-500"
                                  : ""
                                : "") +
                              " form-input cursor-pointer border-[#858585] text-center placeholder:text-[#807f7f] dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 m-auto"
                            }
                            dateFormat="dd MMM, yyyy" // Format for Feb 06, 25 07:00 PM
                          />
                          {/* <Flatpickr
                            disabled={auth.userRole === 1}
                            className={
                              (item.COMPLETION_DATE
                                ? item.EDITOR_ACTION != 11 &&
                                  moment(
                                    item.COMPLETION_DATE,
                                    "YYYY-MM-DD"
                                  ).diff(moment(), "days") <= 3
                                  ? "text-red-500"
                                  : ""
                                : "") +
                              " form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 m-auto"
                            }
                            value={
                              item.COMPLETION_DATE &&
                              item.COMPLETION_DATE !== "Invalid date"
                                ? moment(
                                    item.COMPLETION_DATE,
                                    "YYYY-MM-DD"
                                  ).toDate()
                                : undefined
                            }
                            options={{
                              dateFormat: "d M, Y", // Equivalent to "dd MMM, yyyy"
                              minDate, // Minimum selectable date
                            }}
                            placeholder={"Select"}
                            onChange={(selectedDates) => {
                              if (selectedDates.length > 0) {
                                updateCompletionDate(
                                  item.ID,
                                  moment(selectedDates[0]).format("YYYY-MM-DD")
                                );
                              }
                            }}
                          /> */}
                        </td>
                        <td className="font-light pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center">
                          <Select
                            isDisabled={auth.userRole === 1}
                            options={actions}
                            value={actions.find(
                              (el) => el.value == (item.EDITOR_ACTION || "")
                            )}
                            onChange={(e: any) => {
                              updateEditorAction(item.ID, e.value);
                            }}
                          />
                        </td>
                        {auth?.userRole && auth.userRole > 1 && (
                          <td className="font-light pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] cursor-pointer border border-slate-200 dark:border-zink-500 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-trash m-auto text-[red]"
                              onClick={() => handleShowDeleteModal(item.ID)}
                            >
                              <path d="M3 6h18" />
                              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                            </svg>
                          </td>
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <EssayModal
        show={showModal.status}
        onHide={handleCloseModal}
        type={showModal.type}
        content={showModal.content}
      />

      <LoadingModal show={isUpdatingData} />

      <AlertMessageModal show={alertMsg.show} message={alertMsg.message} />
      <DeleteApplicationEssay
        show={showDeleteModal.show}
        onDelete={deleteApplicationEssay}
        onHide={handleCloseDeleteModal}
        isDeleting={isDeleting}
      />
    </React.Fragment>
  );
};

export default ApplicationCollegeEssays;
