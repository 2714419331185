import React, { memo } from "react";
import Select, {
  type Props as SelectProps,
  ValueContainerProps,
  components,
} from "react-select";

// Good: Custom component declared outside of the Select scope
// @ts-ignore
const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<Option>) => {
  let [values, input] = children as any;
  console.log("Values", props.getValue());

  const selectedValues = props.getValue();
  if (Array.isArray(selectedValues) && selectedValues.length) {
    const plural = values.length === 1 ? "" : "s";
    values = `${selectedValues.map((item) => item.label).join(", ")}`;
  }
  console.log("Values", values);

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const CustomMultiValueRemove = () => null; // Disable the remove button
const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

interface Option {
  readonly label: string;
  readonly value?: string | number;
  readonly options?: Option[];
  readonly isDisabled?: boolean;
}

type MultiSelectProps = SelectProps & {
  options: Option[];
  label?: string;
  labelClassName?: string;
};

export default ({
  className,
  label,
  labelClassName,
  id,
  options,
  required,
  ...otherProps
}: MultiSelectProps) => {
  return (
    <div>
      {label && (
        <h6
          className={
            "inline-block mb-2 text-base font-medium " + labelClassName
          }
        >
          {label} {required && <span className="text-red-500">*</span>}
        </h6>
      )}
      <Select
        className={
          "border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" +
          " " +
          className
        }
        menuPortalTarget={document.body} // This renders the menu at the root of the document.
        isClearable={false}
        isSearchable
        id={id || "choices-multiple-default"}
        name={id || "choices-multiple-default"}
        data-choices
        // menuPortalTarget={document.body} // This renders the menu at the root of the document.
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          // @ts-ignore
          ValueContainer,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            // borderColor: error ? "red" : "gray",
            boxShadow: "none",
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          menu: (base) => ({
            ...base,
            border: "1px solid #dbdcdf00", // Black border around the dropdown modal
            borderRadius: "6px", // Optional: Rounds corners
            overflow: "scroll", // Ensures border applies properly
          }),
          option: (base, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isSelected
              ? "rgb(37 151 130)" // Selected option color
              : isFocused
              ? "white"   // Hover color (white)
              : "white",  // Default background color
            color: isSelected ? "white" : "black",
            ":hover": {  
              backgroundColor: "rgb(37 151 130 / 44%)", // Hover background color
              color: "black", // Text color on hover
            },
          }),
        }}    
        options={options}
        {...otherProps}
      />
    </div>
  );
};
