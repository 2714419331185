import React from "react";
import Select, { type Props as ReactSelectProps } from "react-select";

interface Option {
  readonly label: string;
  readonly value?: string | number | boolean | null;
  readonly options?: Option[];
  readonly isDisabled?: boolean;
}

type SelectProps = ReactSelectProps & {
  options: Option[];
  label?: string;
  labelClassName?: string;
  error?: boolean;
  errorMessage?: string;
  errorClassName?: string;
};

export default ({
  className,
  label,
  labelClassName = "",
  id,
  options,
  required,
  error,
  errorMessage,
  errorClassName,
  ...otherProps
}: SelectProps) => {
  return (
    <React.Fragment>
      {label && (
        <h6
          className={"inline-block mb-2 text-base font-bold " + labelClassName}
        >
          {label} {required && <span className="text-red-500">*</span>}
        </h6>
      )}
      {/* <label
  htmlFor={id || "choices-single-no-sorting"}
  className="font-bold"
>
  Your Label Text
</label> */}
      <Select
        className={`border-slate-200  ${
          error ? "border-red-600" : ""
        } disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 placeholder:text-slate-400 dark:placeholder:text-zink-200 ${className}`}
        id={id || "choices-single-no-sorting"}
        name={id || "choices-single-no-sorting"}
        data-choices
        menuPortalTarget={document.body} // This renders the menu at the root of the document.
        data-choices-sorting-false
        options={options}
        styles={{
          control: (base, state) => ({
            ...base,
            borderColor: error ? "red" : "gray",
            boxShadow: "none",
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (base) => ({
            ...base,
            border: "1px solid #dbdcdf00", // Black border around the dropdown modal
            borderRadius: "6px", // Optional: Rounds corners
            overflow: "scroll", // Ensures border applies properly
          }),
          option: (base, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isSelected
              ? "rgb(37 151 130)" // Selected option color
              : isFocused
              ? "white" // Hover color (white)
              : "white", // Default background color
            color: isSelected ? "white" : "black",
            ":hover": {
              backgroundColor: "rgb(37 151 130 / 44%)", // Hover background color
              color: "black", // Text color on hover
            },
          }),
        }}
        {...otherProps}
      />

      {error && errorMessage && (
        <>
          <i className="fa-solid fa-circle-info text-red-600"></i>{" "}
          <span className={"text-red-600 " + errorClassName}>
            {errorMessage}
          </span>
        </>
      )}
    </React.Fragment>
  );
};
