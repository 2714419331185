import React, { useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import Select from "Common/Components/Select";
import moment from "moment";

import "flatpickr/dist/themes/material_green.css"; // You can customize this theme
import ApplicationCollegeEssays from "./components/ApplicationEssays";
import LoadingModal from "./components/LoadingModal";
import AlertMessageModal from "./components/AlertMessageModal";
import EssayModal from "./components/EssayModal";
import AxiosSingleton from "utils/axiosSingleton";
import useStudentData from "hooks/useStudentData";
import BasicButton from "../../Common/Components/BasicButton";
import { getAuth, useAuthSelector } from "slices/authv2";
// import Tooltip from "Common/Components/Tooltip";
import { useReactToPrint } from "react-to-print";
import { Tooltip } from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DEFAULT_LOGO_5 =
  process.env.PUBLIC_URL +
  "https://collegepass-logos.s3.ap-south-1.amazonaws.com/BHolo.jpeg";

export function numberToString(number: number) {
  const numberMap: { [key: string]: string } = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
    11: "eleven",
    12: "twelve",
    13: "thirteen",
    14: "fourteen",
    15: "fifteen",
    16: "sixteen",
    17: "seventeen",
    18: "eighteen",
    19: "nineteen",
    20: "twenty",
    30: "thirty",
    40: "forty",
    50: "fifty",
    60: "sixty",
    70: "seventy",
    80: "eighty",
    90: "ninety",
  };

  if (number >= 0 && number <= 100) {
    if (number <= 20) {
      return numberMap[number.toString()];
    } else if (number % 10 === 0) {
      return numberMap[number.toString()];
    } else {
      const tens = Math.floor(number / 10) * 10;
      const ones = number % 10;
      return numberMap[tens.toString()] + "-" + numberMap[ones.toString()];
    }
  } else {
    return "Invalid number";
  }
}
export const loadScript = (src: string) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve(true);
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const applicationTypes = [
  { label: "Regular", value: "R_Date" },
  { label: "Early Decision", value: "ED_Date" },
  { label: "Early Action", value: "EA_Date" },
];

const actions = [
  { value: 1, label: "\u00A0" },
  { value: 9, label: "Student Draft Submitted" },
  { value: 12, label: "Editing in Progress" },
  { value: 10, label: "Brainstorming in Progress" },
  { value: 11, label: "Essay Finalized" },
];

const CollegeEssays = () => {
  const axios = AxiosSingleton.getInstance();

  const { encryptedStudentId, studentId, isLoadingStudent, studentData } =
    useStudentData();

  const auth = useAuthSelector(getAuth);

  const [showModal, setShowModal] = useState<{
    status: boolean;
    type: "Essay prompt" | "Sample Essay" | "";
    content: string | null;
  }>({ status: false, type: "", content: null });

  const handleShowModal = (
    type: "Essay prompt" | "Sample Essay" | "",
    content: string | null
  ) => {
    setShowModal({ status: true, type, content });
  };

  const handleCloseModal = () => {
    setShowModal({ status: false, type: "", content: null });
  };
  const [isUpdatingData, setIsUpdatingData] = useState<boolean>(false);

  const [alertMsg, setAlertMsg] = useState({ show: false, message: "" });

  const handleShowAlert = (message: string) => {
    setAlertMsg({ show: true, message });
  };

  const handleCloseAlert = () => {
    setAlertMsg({ show: false, message: "" });
  };

  async function authenticate(
    counsellorEmail?: string,
    docName?: string,
    userId?: string | number,
    collegeId?: string | number,
    emails?: string
  ) {
    // @ts-ignore
    const result = window.google.accounts.oauth2.initCodeClient({
      client_id:
        "761038965200-n70er1c6ke9qdcq9krhrtkkqmh1v42d2.apps.googleusercontent.com",
      scope:
        "https://www.googleapis.com/auth/drive.file" + " openid profile email",
      callback: function (res: any) {
        handleCredentialResponse(
          res,
          counsellorEmail,
          docName,
          userId,
          collegeId,
          emails
        );
      },
      // prompt: "consent",
      prompt: "select_account", // Set to 'none' to try silent authentication
      onError: function handleError(err: any) {
        console.error("Error during authentication:", err);
        // Handle the error in your application (e.g., display an error message)
      },
    });
    result.requestCode();
  }

  const createDoc = async (
    counsellorEmail: string,
    docName: string,
    userId: string | number,
    collegeId: string | number,
    emails: string
  ) => {
    setIsUpdatingData(true);
    try {
      const res = await axios.post(
        "https://new-api.collegepass.org/advising/v1/college-essays/create-doc",
        {
          counsellorEmail: counsellorEmail,
          docName,
          userId,
          docId: collegeId,
          emails,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.CollegePassToken_AP}`,
          },
        }
      );
      setIsUpdatingData(false);

      setEssays((prev) =>
        prev.map((item) => {
          if (item.ID === collegeId) {
            item.GOOGLE_DOC_ID = res.data.data;
          }
          return item;
        })
      );
      // handleShowAlert("Essay Document Created!");

      setTimeout(() => {
        handleCloseAlert();
      }, 2000);
    } catch (err: any) {
      console.log("Error", err);
      console.log("Error", err.response);
      if (err === "Invalid credentials" || err?.response?.status === 401) {
        await authenticate(counsellorEmail, docName, userId, collegeId, emails);
      } else {
        setIsUpdatingData(false);
      }
    }
  };

  async function handleCredentialResponse(
    response: any,
    counsellorEmail?: string,
    docName?: string,
    userId?: string | number,
    collegeId?: string | number,
    emails?: string
  ) {
    console.log("Encoded JWT ID token: " + response.access_token);
    console.log("Encoded JWT ID token: " + response.refresh_token);
    console.log("Encoded JWT ID token: " + JSON.stringify(response));
    let payload = {
      grant_type: "authorization_code",
      client_id:
        "761038965200-n70er1c6ke9qdcq9krhrtkkqmh1v42d2.apps.googleusercontent.com",
      client_secret: "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",
      code: response.code,
      redirect_uri: "postmessage",
    };

    try {
      const res = await axios.post(
        `https://oauth2.googleapis.com/token`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;",
          },
        }
      );
      const response = res.data as any;

      console.log("refresh token: ", response);

      try {
        const res2 = await axios.post(
          "https://new-api.collegepass.org/advising/v1/shared/store-counsellor-email-access-credentials",
          {
            email: counsellorEmail,
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
            redirectURL: "https://developers.google.com/oauthplayground",
            client_id:
              "761038965200-n70er1c6ke9qdcq9krhrtkkqmh1v42d2.apps.googleusercontent.com",
            client_secret: "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",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.CollegePassToken_AP}`,
            },
          }
        );
        console.log("Response", res2);

        if (counsellorEmail && docName && userId && collegeId && emails) {
          try {
            await createDoc(
              counsellorEmail,
              docName,
              userId,
              collegeId,
              emails
            );
          } catch (err) {
            console.log("error creating doc", err);
          }
        }
      } catch (err) {
        console.log("error", err);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  }

  useEffect(() => {
    loadScript("https://accounts.google.com/gsi/client")
      .then((resp) => {
        /*global google*/
        // @ts-ignore
        console.log(window.google);
        // @ts-ignore
        window.google.accounts.oauth2.initTokenClient({
          client_id:
            "761038965200-n70er1c6ke9qdcq9krhrtkkqmh1v42d2.apps.googleusercontent.com",
          scope: "https://www.googleapis.com/auth/drive.file",
          callback: handleCredentialResponse,
          onError: function handleError(err: any) {
            console.error("Error during authentication:", err);
          },
        });
      })
      .catch((err) => console.error("Getting error while initializing", err));
  }, []);

  const updateApplicationType = async (
    essayId: number,
    applicationType: string
  ) => {
    try {
      const result = await axios.post(
        `https://new-api.collegepass.org/advising/v1/college-essays/update-application-type`,
        { essayId, applicationType }
      );
      setEssays((prev) =>
        prev.map((item) => {
          if (item.ID === essayId) {
            item.APPLICATION_TYPE = applicationType;
          }
          return item;
        })
      );
    } catch (err) {
      console.log("Error updating application type:", err);
    }
  };

  const updateEditorAction = async (essayId: number, editorAction: string) => {
    try {
      const result = await axios.post(
        `https://new-api.collegepass.org/advising/v1/college-essays/update-editor-action`,
        { essayId, editorAction }
      );
      setEssays((prev) =>
        prev.map((item) => {
          if (item.ID === essayId) {
            item.EDITOR_ACTION = editorAction;
          }
          return item;
        })
      );
    } catch (err) {
      console.log("Error updating application type:", err);
    }
  };

  const updateCompletionDate = async (
    essayId: number,
    completionDate: string
  ) => {
    try {
      const result = await axios.post(
        `https://new-api.collegepass.org/advising/v1/college-essays/update-completion-date`,
        { essayId, completionDate }
      );
      setEssays((prev) =>
        prev.map((item) => {
          if (item.ID === essayId) {
            item.COMPLETION_DATE = completionDate;
          }
          return item;
        })
      );
    } catch (err) {
      console.log("Error updating application type:", err);
    }
  };

  const [essays, setEssays] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalNoOfUniversities, setTotalNoOfUniversities] = useState(0);
  const [activeEssays, setActiveEssays] = useState(0);
  const [advisors, setAdvisors] = useState<string[]>([]);
  const [studentName, setStudentName] = useState<{
    firstName: string;
    lastName: string;
  }>({ firstName: "", lastName: "" });
  useEffect(() => {
    const getEssays = async (userId: number) => {
      setIsLoading(true);
      try {
        const result = await axios.get(
          `https://new-api.collegepass.org/advising/v1/college-essays/get-essays/${userId}`
        );
        setEssays(result.data.data);

        setActiveEssays((prev) =>
          result.data.data.reduce((value: number, item: any) => {
            if (item.GOOGLE_DOC_ID) {
              value += 1;
            }
            return value;
          }, prev)
        );
        setIsLoading(false);
      } catch (err) {
        setIsLoading(true);
        console.log("Error", err);
      }
    };
    const getUserDetails = async (userId: number) => {
      try {
        const result = await axios.get(
          `https://new-api.collegepass.org/advising/v1/shared/get-user-details/${userId}`
        );

        setTotalNoOfUniversities(result.data.data.noTotalUniv);
        setStudentName({
          firstName: result.data.data.firstName,
          lastName: result.data.data.lastName,
        });
      } catch (err) {
        console.log("Error while getting details", err);
      }
    };
    const getAdvisors = async (userId: number) => {
      try {
        const result = await axios.get(
          `https://new-api.collegepass.org/advising/v1/college-essays/get-advisors-email/${userId}`
        );

        setAdvisors(result.data.data);
      } catch (err) {
        console.log("Error while getting details", err);
      }
    };
    if (studentId) {
      getUserDetails(+studentId);
      getEssays(+studentId);
      getAdvisors(+studentId);
    }
  }, [studentId]);

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    ...(studentName
      ? {
          documentTitle: `${studentName.firstName}_${studentName.lastName}_Roadmap`,
        }
      : {}),
  });

  return (
    <React.Fragment>
      <div ref={contentRef}>
        <div className="grow py-4 flex items-center print:block">
          <img
            src="https://collegepass.s3.ap-south-1.amazonaws.com/school+(1).png"
            className="h-7 max-w-full mr-2 print:hidden"
          />
          <div className="print:flex print:justify-center print:items-center print:m-4">
            <img
              src={DEFAULT_LOGO_5}
              alt="CollegePass"
              className="hidden print:block print:text-clip print:align-middle print:w-[110px]"
            />
          </div>
          <h5 className="text-[1.05rem] font-medium print:text-center">
            College Essays
          </h5>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="grid items-center grid-cols-1 gap-3 mb-5 xl:grid-cols-6 print:hidden">
              <div className="xl:col-span-3">
                <BasicButton
                  className="flex text-white btn bg-[#007bff] border-[#007bff] hover:text-white hover:bg-[#007bff] hover:[#007bff] focus:text-white focus:bg-[#007bff] focus:[#007bff] active:text-white active:bg-[#007bff] active:[#007bff] text-sm font-normal"
                  onClick={() => reactToPrintFn()}
                >
                  {" "}
                  Generate PDF
                </BasicButton>
              </div>
              <div className="xl:col-span-6 xl:col-start-10">
                <div className="flex">
                  <p className="bg-[#e3cc80] rounded-md text-black float-left text-[16px] font-medium tracking-wider mt-0 pt-[10px] pb-[10px] pl-[12px] pr-[12px] text-left">{`${activeEssays} out of ${
                    totalNoOfUniversities || 0
                  } universities activated for essay editing`}</p>
                  {/* <div className="relative grow ml-4">
                                    <button className="rounded-full p-3 mr-4 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="7 10 12 15 17 10"/><line x1="12" x2="12" y1="15" y2="3"/></svg></button>
                                </div> */}
                  {/* <CreatableSelect
                                    className="w-3/4 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800" id="" name="choices-single-no-search"
                                    isClearable
                                    isSearchable={false}
                                    options={OptionGroups}
                                /> */}
                </div>
              </div>
            </div>
            <div className="">
              <table className="w-full">
                <thead className="ltr:text-left rtl:text-right bg-[#6b6969] text-white font-medium">
                  <tr>
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center">
                      S.No.
                    </th>
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center min-w-32">
                      College Name
                    </th>
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center min-w-28">
                      Essay prompt
                    </th>
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center min-w-28">
                      Sample Essay
                    </th>
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center">
                      Essay Document
                    </th>
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] w-48 font-normal border border-slate-200 dark:border-zink-500 text-center">
                      Application Type
                    </th>
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center min-w-28">
                      Application Deadline
                    </th>
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] min-w-44 font-normal border border-slate-200 dark:border-zink-500 text-center">
                      Completion Deadline
                    </th>
                    <th className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] font-normal border border-slate-200 dark:border-zink-500 text-center w-60">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {isLoading || isLoadingStudent ? (
                    <tr>
                      <td className="font-medium text-center pt-4" colSpan={9}>
                        Loading...
                      </td>
                    </tr>
                  ) : !essays.length ? (
                    <tr>
                      <td className="font-medium text-center pt-4" colSpan={9}>
                        -
                      </td>
                    </tr>
                  ) : (
                    essays.map((item, idx) => {
                      const type = item.APPLICATION_TYPE
                        ? item.APPLICATION_TYPE === "R_Date"
                          ? "R_Date"
                          : item.APPLICATION_TYPE === "ED_Date"
                          ? "ED_Date"
                          : item.APPLICATION_TYPE === "EA_Date"
                          ? "EA_Date"
                          : "R_Date"
                        : "R_Date";
                      let applicationDeadline =
                        item.COLLEGE_NAME === "University of Pittsburgh"
                          ? "Rolling"
                          : item.collegeData.Colleges[type]
                          ? moment(item.collegeData.Colleges[type]).isValid()
                            ? moment(item.collegeData.Colleges[type]).format(
                                "LL"
                              )
                            : item.collegeData.Colleges[type]
                          : " - ";
                      const minDate = moment().toDate();
                      const maxDate =
                        applicationDeadline && applicationDeadline === "Rolling"
                          ? undefined
                          : moment(
                              applicationDeadline,
                              "MMMM D, YYYY"
                            ).isValid()
                          ? moment(applicationDeadline, "MMMM D, YYYY")
                              .subtract(2, "days")
                              .toDate()
                          : undefined;

                      return (
                        <tr key={item.ID}>
                          <td className="font-medium pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center text-[13px] font-normal">
                            {idx + 1}
                          </td>
                          <td className="font-medium pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center text-[13px] font-normal">
                            {item.COLLEGE_NAME}
                          </td>
                          <td
                            className="font-medium pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] hover:text-[#147cfa] cursor-pointer border border-slate-200 dark:border-zink-500 text-center text-[13px] font-normal"
                            onClick={() =>
                              handleShowModal(
                                "Essay prompt",
                                item.collegeData?.Colleges?.Essay_Prompt
                              )
                            }
                          >
                            Essay Prompt
                          </td>
                          <td
                            className="pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] hover:text-[#147cfa] cursor-pointer border border-slate-200 dark:border-zink-500 text-center text-[13px] font-normal"
                            onClick={() =>
                              handleShowModal(
                                "Sample Essay",
                                item.collegeData?.Colleges?.essaysamples
                                  ? item.collegeData?.Colleges.essaysamples
                                  : "No Sample Essay"
                              )
                            }
                          >
                            Sample Essay
                          </td>
                          <td className="font-medium pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center">
                            <Tooltip
                              theme={{ target: "w-fit m-auto" }}
                              content={
                                auth.userRole === 1 ? (
                                  item.GOOGLE_DOC_ID ? (
                                    "Open"
                                  ) : (
                                    "Essay not activated"
                                  )
                                ) : item.GOOGLE_DOC_ID ? (
                                  "Open"
                                ) : activeEssays < totalNoOfUniversities ? (
                                  "Click to activate"
                                ) : (
                                  <span>
                                    {`Your current advising plan includes ${numberToString(
                                      activeEssays
                                    )} (${activeEssays}) universities!`}
                                    <br />
                                    {`You can contact your advisor if you'd like to add more universities.`}
                                  </span>
                                )
                              }
                              className="text-center"
                            >
                              <i
                                aria-disabled={auth.userRole === 1}
                                onClick={() => {
                                  if (item.GOOGLE_DOC_ID) {
                                    window.open(
                                      `https://docs.google.com/document/d/${item.GOOGLE_DOC_ID}`,
                                      "__blank"
                                    );
                                  } else {
                                    auth?.userRole &&
                                      auth.userRole > 1 &&
                                      activeEssays < totalNoOfUniversities &&
                                      createDoc(
                                        auth.email!, // need to replace with counsellor email
                                        `${studentName.firstName} ${studentName.lastName} <> Essay-${item.COLLEGE_NAME} | CollegePass`, // need to update with student name
                                        +studentId!,
                                        item.ID,
                                        [
                                          ...advisors,
                                          "tech.support@collegepass.org",
                                        ].toString()
                                      );
                                  }
                                }}
                                className={`fab fa-google-drive m-auto ${
                                  item.GOOGLE_DOC_ID
                                    ? "text-green-600"
                                    : "text-red-600"
                                } cursor-pointer`}
                              />
                            </Tooltip>
                          </td>

                          <td className="font-medium pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center text-[13px] font-normal">
                            <Select
                              options={applicationTypes}
                              value={applicationTypes.find(
                                (el) =>
                                  el.value ==
                                  (item.APPLICATION_TYPE || "R_Date")
                              )}
                              onChange={(e: any) =>
                                updateApplicationType(item.ID, e.value)
                              }
                            />
                          </td>
                          <td className="font-medium pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center text-[13px] font-normal">
                            {applicationDeadline &&
                            applicationDeadline === "Rolling"
                              ? "Rolling"
                              : moment(
                                  applicationDeadline,
                                  "MMMM D, YYYY"
                                ).isValid()
                              ? moment(
                                  applicationDeadline,
                                  "MMMM D, YYYY"
                                ).format("DD MMM, YYYY")
                              : applicationDeadline}
                          </td>
                          <td className="font-medium pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center text-[13px] font-normal">
                            <DatePicker
                              selected={
                                item.COMPLETION_DATE &&
                                item.COMPLETION_DATE !== "Invalid date"
                                  ? moment(
                                      item.COMPLETION_DATE,
                                      "YYYY-MM-DD"
                                    ).toDate()
                                  : undefined
                              }
                              // showTimeSelect
                              onChange={(date: Date | null) => {
                                if (date) {
                                  // setStartDate(date);
                                  updateCompletionDate(
                                    item.ID,
                                    moment(date).format("YYYY-MM-DD")
                                  );
                                }
                              }}
                              disabled={auth.userRole === 1}
                              minDate={minDate}
                              maxDate={maxDate}
                              placeholderText="Select"
                              className={
                                (item.COMPLETION_DATE &&
                                item.COMPLETION_DATE !== "Invalid date" &&
                                item.EDITOR_ACTION !== 11 &&
                                moment(item.COMPLETION_DATE, "YYYY-MM-DD").diff(
                                  moment(),
                                  "days"
                                ) <= 3
                                  ? "text-red-500"
                                  : "") +
                                " block w-full rounded-md border border-[#858585] dark:border-zinc-500 bg-white dark:bg-zinc-700 px-4 py-2 text-gray-900 dark:text-zinc-100 placeholder-gray-400 dark:placeholder-zinc-200 dark:focus:border-custom-800 dark:focus:ring-custom-700 focus:ring-blue-500 focus:outline-none text-center"
                              }
                              dateFormat="dd MMM, yyyy" // Format for Feb 06, 25 07:00 PM
                            />
                            {/* <Flatpickr
                              disabled={auth.userRole === 1}
                              className={
                                (item.COMPLETION_DATE &&
                                item.COMPLETION_DATE !== "Invalid date" &&
                                item.EDITOR_ACTION !== 11 &&
                                moment(item.COMPLETION_DATE, "YYYY-MM-DD").diff(
                                  moment(),
                                  "days"
                                ) <= 3
                                  ? "text-red-500"
                                  : "") +
                                " form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 m-auto"
                              }
                              // disabled={isStudent || isParent}
                              value={
                                item.COMPLETION_DATE &&
                                item.COMPLETION_DATE !== "Invalid date"
                                  ? moment(
                                      item.COMPLETION_DATE,
                                      "YYYY-MM-DD"
                                    ).toDate()
                                  : undefined
                              }
                              options={{
                                dateFormat: "d M, Y", // Equivalent to "dd MMM, yyyy"
                                minDate, // Minimum selectable date
                                maxDate, // Maximum selectable date
                              }}
                              placeholder={"Select"}
                              onChange={(selectedDates) => {
                                if (selectedDates.length > 0) {
                                  updateCompletionDate(
                                    item.ID,
                                    moment(selectedDates[0]).format(
                                      "YYYY-MM-DD"
                                    )
                                  );
                                }
                              }}
                            /> */}
                          </td>
                          <td className="font-medium pl-[.6rem] pr-[.6rem] pt-[.75rem] pb-[.75rem] text-[13px] border border-slate-200 dark:border-zink-500 text-center text-[13px] font-normal">
                            <Select
                              isDisabled={auth.userRole === 1}
                              options={actions}
                              value={actions.find(
                                (el) => el.value == (item.EDITOR_ACTION || "")
                              )}
                              onChange={(e: any) => {
                                updateEditorAction(item.ID, e.value);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ApplicationCollegeEssays
          setActiveEssays={setActiveEssays}
          activeEssays={activeEssays}
          totalNoOfUniversities={totalNoOfUniversities}
          studentId={studentId}
          studentName={(
            studentName.firstName +
            " " +
            studentName.lastName
          ).trim()}
        />
      </div>
      <EssayModal
        show={showModal.status}
        onHide={handleCloseModal}
        type={showModal.type}
        content={showModal.content}
      />

      <LoadingModal show={isUpdatingData} />
      {/* <LoadingModal show={true} /> */}
      {/* <AlertMessageModal show={true} message={"testing"} /> */}

      <AlertMessageModal show={alertMsg.show} message={alertMsg.message} />
    </React.Fragment>
  );
};

export default CollegeEssays;
