import Holo from "../../assets/images/loader_animated.svg";
// import "./Loading.scss";

function Loading() {
  return (
    <div className="bg-black h-screen w-screen flex items-center justify-center">
      <div className="h-screen w-screen flex items-center justify-center animate-fadeIn">
        <img
          src={Holo}
          alt="loader"
          className="h-[70px]"
        />
      </div>
    </div>
  );
}

export default Loading;
