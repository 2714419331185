import Modal from "Common/Components/Modal";

function LoadingModal({ show }: { show: boolean }) {
  return (
    <Modal show={show} className="">
      <Modal.Body>
        <svg xmlns="http://www.w3.org/2000/svg" className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" version="1.0" width="64px" height="64px" viewBox="0 0 128 128"><rect x="0" y="0" width="100%" height="100%" fill="#ffffff00" /><g><path d="M59.6 0h8v40h-8V0z" fill="#000000"/><path d="M59.6 0h8v40h-8V0z" fill="#cccccc" transform="rotate(30 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#cccccc" transform="rotate(60 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#cccccc" transform="rotate(90 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#cccccc" transform="rotate(120 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#b2b2b2" transform="rotate(150 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#999999" transform="rotate(180 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#7f7f7f" transform="rotate(210 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#666666" transform="rotate(240 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#4c4c4c" transform="rotate(270 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#333333" transform="rotate(300 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#191919" transform="rotate(330 64 64)"/><animateTransform attributeName="transform" type="rotate" values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64" calcMode="discrete" dur="1080ms" repeatCount="indefinite"></animateTransform></g></svg>
      </Modal.Body>
    </Modal>
  );
}

export default LoadingModal;
