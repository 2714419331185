import AxiosSingleton from "utils/axiosSingleton";
import Holo from "../../assets/images/loader_animated.svg";
import { useEffect } from "react";
import { loadScript } from "pages/CollegeEssays";
import { getAuth, useAuthSelector } from "slices/authv2";
import BasicButton from "Common/Components/BasicButton";

function GMeetAuthentication() {
  const axios = AxiosSingleton.getInstance();
  const auth = useAuthSelector(getAuth);
  useEffect(() => {
    if (auth.email) {
      loadScript("https://accounts.google.com/gsi/client")
        .then(() => {
          setTimeout(() => {
            authenticate(auth.email!);
          }, 1500);
        })
        .catch((err) => console.error("Error loading Google script", err));
    }
  }, [auth.email]);

  async function authenticate(counsellorEmail: string) {
    // @ts-ignore
    const result = window.google.accounts.oauth2.initCodeClient({
      client_id:
        "1010100426890-9o926h57c8luiog6ugjgadcqhh6tb210.apps.googleusercontent.com",
      scope:
        "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/chat.messages https://www.googleapis.com/auth/chat.spaces openid profile email",
      callback: function (res: any) {
        handleCredentialResponse(res, counsellorEmail);
      },
      prompt: "select_account",
      onError: function handleError(err: any) {
        console.error("Error during authentication:", err);
      },
    });
    result.requestCode();
  }

  async function handleCredentialResponse(
    response: any,
    counsellorEmail: string
  ) {
    console.log("Response:", response);
    const payload = {
      grant_type: "authorization_code",
      client_id:
        "1010100426890-9o926h57c8luiog6ugjgadcqhh6tb210.apps.googleusercontent.com",
      client_secret: "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",
      code: response.code,
      redirect_uri: "postmessage",
    };

    try {
      const res = await axios.post(
        `https://oauth2.googleapis.com/token`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;",
          },
        }
      );
      const tokenResponse = res.data;
      console.log("Token response:", tokenResponse);

      // Example API call using the tokens
      await axios.post(
        "https://new-api.collegepass.org/advising/v1/meeting/store-counsellor-credentials",
        {
          email: counsellorEmail,
          accessToken: tokenResponse.access_token,
          refreshToken: tokenResponse.refresh_token,
          redirectURL: "https://developers.google.com/oauthplayground",
          client_id:
            "1010100426890-9o926h57c8luiog6ugjgadcqhh6tb210.apps.googleusercontent.com",
          client_secret: "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",
        }
      );

      // Redirect or update UI as needed
      setTimeout(() => {
        window.open("/", "_self");
      }, 1000);
    } catch (err) {
      console.error("Error handling credentials:", err);
    }
  }

  return (
    // <div className="bg-black h-screen w-screen flex items-center justify-center">
    //   <div className="h-screen w-screen flex items-center justify-center animate-fadeIn">
    //     <img src={Holo} alt="loader" className="h-[70px]" />
    //   </div>
    // </div>
    // <></>
    <div className="text-center">
      <BasicButton
      className="bg-[#e32552] border-[#e32552] pl-[15px] pr-[15px] text-white text-[14px] hover:bg-[#b1183c] hover:border-[#b1183c] tracking-wider font-medium p-2 rounded"
      onClick={() => authenticate(auth.email!)}>
        Complete Authorization
      </BasicButton>
    </div>
  );
}

export default GMeetAuthentication;
