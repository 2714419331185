import React from "react";

import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import Layout from "Layout";
import ChunkErrorBoundary from "pages/ChunkErrorBoundary";

const MyStudents = lazy(() => import("../pages/MyStudents"));
const MyProfile = lazy(() => import("../pages/MyProfile"));
const Meetings = lazy(() => import("../pages/Meetings"));
const Olympiads = lazy(() => import("../pages/Olympiads"));
const Internships = lazy(() => import("../pages/Internships"));
const Competitions = lazy(() => import("../pages/Competitions"));
const OnlineCourses = lazy(() => import("../pages/OnlineCourses"));
const ReadingList = lazy(() => import("../pages/ReadingList"));
const Downloads = lazy(() => import("../pages/Downloads"));
const Hackathons = lazy(() => import("../pages/Hackathons"));

const Task = lazy(() => import("../pages/Task"));
const Testing = lazy(() => import("../pages/Testing"));
const AdvisorNotes = lazy(() => import("../pages/AdvisorNotes"));
const Grades = lazy(() => import("../pages/Grades"));
const GradesValue = lazy(() => import("../pages/GradesValue"));
const AdmissionsPitch = lazy(() => import("../pages/AdmissionsPitch"));
const AdmissionsPitchDoc = lazy(() => import("../pages/AdmissionPitchDoc"));
const AIassistant = lazy(() => import("../pages/AIassistant"));
const StudentInformation = lazy(() => import("../pages/StudentInformation"));
const SchoolSupport = lazy(() => import("../pages/SchoolSupport"));
const CollegeEssays = lazy(() => import("../pages/CollegeEssays"));
const Extracurriculars = lazy(() => import("../pages/Extracurriculars"));
const SummerPlan = lazy(() => import("../pages/SummerPlan"));
const NGO = lazy(() => import("../pages/NGO"));
const Professors = lazy(() => import("../pages/Professors"));
const CpEmail = lazy(() => import("../pages/CpEmail"));
const Connections = lazy(() => import("../pages/Connections"));
const RecommendedBooks = lazy(() => import("../pages/RecommendedBooks"));
const ResearchPrograms = lazy(() => import("../pages/ResearchPrograms"));
const SummerSchoolEssays = lazy(() => import("../pages/SummerSchoolEssays"));
const Scholarships = lazy(() => import("../pages/Scholarships"));
const SummerPrograms = lazy(() => import("../pages/SummerPrograms"));
const ShortlistedCollegesUs = lazy(
  () => import("pages/Shortlisting/ShortlistedCollegesUs")
);
const ShortlistedCollegesUk = lazy(
  () => import("pages/Shortlisting/ShortlistedCollegesUk")
);
const ShortlistedCollegesCanada = lazy(
  () => import("pages/Shortlisting/ShortlistedCollegesCanada")
);
const ShortlistedEurope = lazy(
  () => import("../pages/Shortlisting/ShortlistedEurope")
);
const ApacShortlisting = lazy(
  () => import("../pages/Shortlisting/ApacShortlisting")
);
const EuropeShortlisting = lazy(
  () => import("../pages/Shortlisting/EuropeShortlisting")
);
const ShortlistedApac = lazy(
  () => import("../pages/Shortlisting/ShortlistedApac")
);
const CanadaShortlisting = lazy(
  () => import("../pages/Shortlisting/CanadaShortlisting")
);

const ShortlistedCollegesIntegratedMed = lazy(
  () => import("pages/Shortlisting/ShortlistedCollegesIntegratedMed")
);

const UkShortlisting = lazy(
  () => import("../pages/Shortlisting/UkShortlisting")
);
const UsShortlisting = lazy(
  () => import("../pages/Shortlisting/UsShortlisting")
);

// Email
const Mailbox = lazy(() => import("../pages/Email"));

// Notes
const Notes = lazy(() => import("../pages/Notes"));

// Users
const UserListView = lazy(() => import("../pages/Users/ListView"));
const UserGridView = lazy(() => import("../pages/Users/GridView"));

// Reusable Component
const ReusableComponent = lazy(() => import("../pages/Reusable"));

const IndependentProject1 = lazy(
  () => import("../pages/IndependentProjects/IndependentProject1")
);

const IndependentProject2 = lazy(
  () => import("../pages/IndependentProjects/IndependentProject2")
);

const IndependentProject3 = lazy(
  () => import("../pages/IndependentProjects/IndependentProject3")
);

const IndependentProjectDoc1 = lazy(
  () => import("../pages/IndependentProjectsDoc/IndependentProject1")
);

const IndependentProjectDoc2 = lazy(
  () => import("../pages/IndependentProjectsDoc/IndependentProject2")
);

const IndependentProjectDoc3 = lazy(
  () => import("../pages/IndependentProjectsDoc/IndependentProject3")
);

const ResearchFocus1 = lazy(
  () => import("../pages/ResearchFocus/ResearchFocus1")
);

const ResearchFocus2 = lazy(
  () => import("../pages/ResearchFocus/ResearchFocus2")
);

const ResearchFocus3 = lazy(
  () => import("../pages/ResearchFocus/ResearchFocus3")
);

const ResearchFocusDoc1 = lazy(
  () => import("../pages/ResearchFocusDocs/ResearchFocus1")
);

const ResearchFocusDoc2 = lazy(
  () => import("../pages/ResearchFocusDocs/ResearchFocus2")
);

const ResearchFocusDoc3 = lazy(
  () => import("../pages/ResearchFocusDocs/ResearchFocus3")
);
// const AIassistant = lazy(() => import("../pages/AIassistant"));
const AdmissionPitch = lazy(() => import("../pages/AdmissionsPitch"));

const SummerStrategyOutline = lazy(
  () => import("../pages/SummerStrategyOutline")
);

const SummerStrategyOutlineDoc = lazy(
  () => import("../pages/SummerStrategyOutlineDoc")
);

const EssayThemes = lazy(() => import("../pages/EssayThemes"));
const EssayThemesDoc = lazy(() => import("../pages/EssayThemesDoc"));

const Home = lazy(() => import("pages/Home"));
const LoginV1 = lazy(() => import("pages/Authentication"));

const ManagerMyStudents = lazy(() => import("../pages/Manager/MyStudents"));
const Loading = lazy(() => import("../pages/Loading"));
const Landing = lazy(() => import("../pages/Landing"));
const EmailAuthentication = lazy(() => import("../pages/EmailAuthentication"));

interface RouteObject {
  path: string;
  // element: <React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  element: any;
  exact?: boolean;
}

const authProtectedRoutes: any[] = [
  { path: "/reusable-components", element: <ReusableComponent /> },

  // CollegePass Routes
  { path: "/my-profile", element: <MyProfile /> },
  { path: "/:studentId", element: <MyProfile /> },
  { path: "/my-students", element: <MyStudents /> },
  { path: "/manager/students", element: <ManagerMyStudents /> },
  { path: "/meetings", element: <Meetings /> },
  { path: "/meetings/:studentId", element: <Meetings /> },
  { path: "/task", element: <Task /> },
  { path: "/task/:studentId", element: <Task /> },
  { path: "/olympiads", element: <Olympiads /> },
  { path: "/olympiads/:studentId", element: <Olympiads /> },
  { path: "/testing", element: <Testing /> },
  { path: "/testing/:studentId", element: <Testing /> },
  { path: "/competitions/:studentId", element: <Competitions /> },
  { path: "/hackathons", element: <Hackathons /> },
  { path: "/hackathons/:studentId", element: <Hackathons /> },
  { path: "/internships", element: <Internships /> },
  { path: "/internships/:studentId", element: <Internships /> },
  { path: "/downloads", element: <Downloads /> },
  { path: "/downloads/:studentId", element: <Downloads /> },
  { path: "/reading-list", element: <ReadingList /> },
  { path: "/reading-list/:studentId", element: <ReadingList /> },
  { path: "/online-courses", element: <OnlineCourses /> },
  { path: "/online-courses/:studentId", element: <OnlineCourses /> },
  { path: "/advisor-notes/:studentId", element: <AdvisorNotes /> },
  { path: "/grades", element: <Grades /> },
  { path: "/grades/:studentId", element: <Grades /> },
  { path: "/grade/:gradeId", element: <GradesValue /> },
  { path: "/grade/:gradeId/:studentId", element: <GradesValue /> },
  { path: "/admissions-pitch", element: <AdmissionsPitch /> },
  { path: "/admissions-pitch/:studentId", element: <AdmissionsPitch /> },
  { path: "/admissions-pitch-doc", element: <AdmissionsPitchDoc /> },
  { path: "/admissions-pitch-doc/:studentId", element: <AdmissionsPitchDoc /> },
  { path: "/student-information", element: <StudentInformation /> },
  { path: "/student-information/:studentId", element: <StudentInformation /> },
  { path: "/cp-email", element: <CpEmail /> },
  { path: "/cp-email/:studentId", element: <CpEmail /> },
  { path: "/connections", element: <Connections /> },
  { path: "/connections/:studentId", element: <Connections /> },
  { path: "/school-support", element: <SchoolSupport /> },
  { path: "/school-support/:studentId", element: <SchoolSupport /> },
  { path: "/college-essays", element: <CollegeEssays /> },
  { path: "/college-essays/:studentId", element: <CollegeEssays /> },
  { path: "/extracurriculars", element: <Extracurriculars /> },
  { path: "/extracurriculars/:studentId", element: <Extracurriculars /> },
  { path: "/ngo", element: <NGO /> },
  { path: "/ngo/:studentId", element: <NGO /> },
  { path: "/professors", element: <Professors /> },
  { path: "/professors/:studentId", element: <Professors /> },
  { path: "/research-programs", element: <ResearchPrograms /> },
  { path: "/research-programs/:studentId", element: <ResearchPrograms /> },
  { path: "/summer-school-essay", element: <SummerSchoolEssays /> },
  { path: "/summer-school-essay/:studentId", element: <SummerSchoolEssays /> },
  { path: "/recommended-books", element: <RecommendedBooks /> },
  { path: "/recommended-books/:studentId", element: <RecommendedBooks /> },
  { path: "/scholarships", element: <Scholarships /> },
  { path: "/scholarships/:studentId", element: <Scholarships /> },
  { path: "/us-shortlisting", element: <UsShortlisting /> },
  { path: "/us-shortlisting/:studentId", element: <UsShortlisting /> },
  { path: "/uk-shortlisting", element: <UkShortlisting /> },
  { path: "/uk-shortlisting/:studentId", element: <UkShortlisting /> },
  { path: "/shortlisted-colleges-us", element: <ShortlistedCollegesUs /> },
  { path: "/independent-project-1", element: <IndependentProject1 /> },
  {
    path: "/independent-project-1/:studentId",
    element: <IndependentProject1 />,
  },
  { path: "/independent-project-2", element: <IndependentProject2 /> },
  {
    path: "/independent-project-2/:studentId",
    element: <IndependentProject2 />,
  },
  { path: "/independent-project-3", element: <IndependentProject3 /> },
  {
    path: "/independent-project-3/:studentId",
    element: <IndependentProject3 />,
  },

  { path: "/independent-project-doc-1", element: <IndependentProjectDoc1 /> },
  {
    path: "/independent-project-doc-1/:studentId",
    element: <IndependentProjectDoc1 />,
  },
  { path: "/independent-project-doc-2", element: <IndependentProjectDoc2 /> },
  {
    path: "/independent-project-doc-2/:studentId",
    element: <IndependentProjectDoc2 />,
  },
  { path: "/independent-project-doc-3", element: <IndependentProjectDoc3 /> },
  {
    path: "/independent-project-doc-3/:studentId",
    element: <IndependentProjectDoc3 />,
  },

  { path: "/research-focus-1", element: <ResearchFocus1 /> },
  {
    path: "/research-focus-1/:studentId",
    element: <ResearchFocus1 />,
  },

  { path: "/research-focus-2", element: <ResearchFocus2 /> },
  {
    path: "/research-focus-2/:studentId",
    element: <ResearchFocus2 />,
  },
  { path: "/research-focus-3", element: <ResearchFocus3 /> },
  {
    path: "/research-focus-3/:studentId",
    element: <ResearchFocus3 />,
  },

  {
    path: "/research-focus-doc-1",
    element: <ResearchFocusDoc1 />,
  },

  {
    path: "/research-focus-doc-1/:studentId",
    element: <ResearchFocusDoc1 />,
  },

  {
    path: "/research-focus-doc-2",
    element: <ResearchFocusDoc2 />,
  },

  {
    path: "/research-focus-doc-2/:studentId",
    element: <ResearchFocusDoc2 />,
  },

  {
    path: "/research-focus-doc-3",
    element: <ResearchFocusDoc3 />,
  },

  {
    path: "/research-focus-doc-3/:studentId",
    element: <ResearchFocusDoc3 />,
  },
  // {
  //   path: "/ai-assistant",
  //   element: <AIassistant />,
  // },
  {
    path: "/admission-pitch",
    element: <AdmissionPitch />,
  },
  // {
  //   path: "/ai-assistant/:studentId",
  //   element: <AIassistant />,
  // },
  {
    path: "/admission-pitch/:studentId",
    element: <AdmissionPitch />,
  },

  {
    path: "/summer-strategy-outline",
    element: <SummerStrategyOutline />,
  },
  {
    path: "/summer-strategy-outline/:studentId",
    element: <SummerStrategyOutline />,
  },

  {
    path: "/summer-strategy-outline-doc",
    element: <SummerStrategyOutlineDoc />,
  },
  {
    path: "/summer-strategy-outline-doc/:studentId",
    element: <SummerStrategyOutlineDoc />,
  },

  {
    path: "/essay-themes",
    element: <EssayThemes />,
  },
  {
    path: "/essay-themes/:studentId",
    element: <EssayThemes />,
  },
  {
    path: "/essay-themes-doc",
    element: <EssayThemesDoc />,
  },
  {
    path: "/essay-themes-doc/:studentId",
    element: <EssayThemesDoc />,
  },
  {
    path: "/shortlisted-colleges-us/:studentId",
    element: <ShortlistedCollegesUs />,
  },
  {
    path: "/shortlisted-colleges-us/:studentId",
    element: <ShortlistedCollegesUs />,
  },
  { path: "/shortlisted-colleges-uk", element: <ShortlistedCollegesUk /> },
  {
    path: "/shortlisted-colleges-uk/:studentId",
    element: <ShortlistedCollegesUk />,
  },
  { path: "/canada-shortlisting", element: <CanadaShortlisting /> },
  { path: "/canada-shortlisting/:studentId", element: <CanadaShortlisting /> },
  {
    path: "/shortlisted-colleges-canada",
    element: <ShortlistedCollegesCanada />,
  },
  {
    path: "/shortlisted-colleges-canada/:studentId",
    element: <ShortlistedCollegesCanada />,
  },
  { path: "/shortlisted-colleges-europe", element: <ShortlistedEurope /> },
  {
    path: "/shortlisted-colleges-europe/:studentId",
    element: <ShortlistedEurope />,
  },
  { path: "/shortlisted-colleges-apac", element: <ShortlistedApac /> },
  {
    path: "/shortlisted-colleges-apac/:studentId",
    element: <ShortlistedApac />,
  },
  {
    path: "/shortlisted-colleges-integrated-med",
    element: <ShortlistedCollegesIntegratedMed />,
  },
  {
    path: "/shortlisted-colleges-integrated-med/:studentId",
    element: <ShortlistedCollegesIntegratedMed />,
  },
  { path: "/apac", element: <ApacShortlisting /> },
  { path: "/apac/:studentId", element: <ApacShortlisting /> },
  { path: "/europe-shortlisting", element: <EuropeShortlisting /> },
  { path: "/europe-shortlisting/:studentId", element: <EuropeShortlisting /> },
  { path: "/summer-plan", element: <SummerPlan /> },
  { path: "/summer-plan/:studentId", element: <SummerPlan /> },
  { path: "/summer-programs", element: <SummerPrograms /> },
  { path: "/summer-programs/:studentId", element: <SummerPrograms /> },

  // Dashboard
  { path: "/", element: <Home /> },

  // Mailbox
  { path: "/emails", element: <Mailbox /> },
  { path: "/emails/:studentId", element: <Mailbox /> },

  // Notes
  { path: "/apps-notes", element: <Notes /> },

  // Users
  { path: "/apps-users-list", element: <UserListView /> },
  { path: "/apps-users-grid", element: <UserGridView /> },
];

const publicRoutes = [
  {
    path: "/ai-assistant",
    element: (
      <Suspense fallback={<Loading />}>
        <AIassistant />{" "}
      </Suspense>
    ),
  },
  {
    path: "/ai-assistant/:studentId",
    element: (
      <Suspense fallback={<Loading />}>
        <AIassistant />
      </Suspense>
    ),
  },
  { path: "/login", element: <LoginV1 /> },
  { path: "/loading", element: <Loading /> },
  { path: "/landing/:token", element: <Landing /> },
  { path: "/completeEmailAuthorization", element: <EmailAuthentication /> },
];

const router = createBrowserRouter([
  {
    element: (
      <ChunkErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Layout />
        </Suspense>
      </ChunkErrorBoundary>
    ),
    children: authProtectedRoutes,
  },
  ...publicRoutes,
]);

export { router, authProtectedRoutes, publicRoutes };
